const { cvPickerItems } = require("cvpop-constants-sdk")
const { nationalities } = require("cvpop-collections-sdk")
const { t } = require("cvpop-localization-sdk")

const { dateToString, formatAddress } = require("../../script")
const { contactInfoTag } = require("./contact-info-tag")
const { sectionTitle } = require("./section-title")

const getContactInfoIcon = contact => {
    if (!contact.Use.Code) return "";
    switch (contact.Use.Code) {
        case 'mobile': return 'fas fa-mobile-alt'
        case 'home': return 'fas fa-phone'
        case 'work': return 'fas fa-phone-square-alt'
        case 'personal':
        case 'business':
        case 'blog':
        case 'portfolio': return 'fas fa-globe'
        case 'gtalk': return 'fab fa-google'
        case 'aim': return 'fas fa-comments'
        default: return `fab fa-${contact.Use.Code}`
    }
}

module.exports = {
    contactInfo: cv => {

        const { lng } = cv.Metadata
        const { ContactInfo, Demographics } = cv.SkillsPassport.LearnerInfo.Identification
        const { Email, Address, Telephone = [], InstantMessaging = [], Website = [], SocialAccount = [] } = ContactInfo
        const { Birthdate = {}, Gender = {}, Nationality = [] } = Demographics

        const locale = lng === 'nb' ? 'no' : lng


        return `
        <div class="identification-container container">
            ${sectionTitle(cv, "cvLabelPersonalInfo", "ContactInfo", "fas fa-address-card")}
            <div class="identification-content content">
                ${Email?.Contact ? contactInfoTag(Email?.Contact, "fas fa-envelope") : ""}

                ${formatAddress(Address?.Contact, locale) ? contactInfoTag(formatAddress(Address?.Contact, locale), "fas fa-map-marker-alt") : ""}
                ${Birthdate.Year ? contactInfoTag(dateToString(cv, Birthdate, "birthdate", locale), "far fa-calendar") : ""}
                ${Gender.Code ? contactInfoTag(t((cvPickerItems.gender().find(e => e.code === Gender.Code) || { label: "" }).label, { locale }), "fas fa-venus-mars") : ""}

                ${Nationality.map(n => contactInfoTag((nationalities[locale].find(v => v.code === n.Code) || { label: n.Label || n.Code }).label, "far fa-flag")).join("")}

                ${Telephone.map(c => contactInfoTag(c.Contact, getContactInfoIcon(c))).join("")}
                ${Website.map(c => contactInfoTag(c.Contact, getContactInfoIcon(c))).join("")}
                ${InstantMessaging.map(c => contactInfoTag(c.Contact, getContactInfoIcon(c))).join("")}
                ${SocialAccount.map(c => contactInfoTag(c.Contact, getContactInfoIcon(c))).join("")}

            </div>
        </div>
        `
    }
}
