import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'app',
    initialState: {
        offering: null,
        offeringProductList: [],

        cvNewFlowEnabled: true,
        cvPreviewEnabled: false,

        spin: false
    },
    reducers: {
        setOffering(state, action) {
            state.offering = action.payload
        },
        setOfferingProductList(state, action) {
            state.offeringProductList = action.payload
        },
        setCvNewFlowEnabled(state, action) {
            state.cvNewFlowEnabled = action.payload
        },
        setCvPreviewEnabled(state, action) {
            state.cvPreviewEnabled = action.payload
        },
        setSpin(state, action) {
            state.spin = action.payload
        }
    }
})

export default appSlice