import { collection, doc } from "firebase/firestore";

import { t, I18n } from "cvpop-localization-sdk"
import { citywithcountricodes } from "cvpop-collections-sdk";

import moment from "moment"
import imageCompression from 'browser-image-compression';

import { db } from "./firebase";


// APP --------------------------------------------
export const createFbId = () => doc(collection(db, "00_TMP_00")).id;

export const fileToBase64 = file => new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = e => rej(e)
    reader.onload = () => res(reader.result);
})

export const imageToBase64 = file => new Promise((res, rej) => {

    const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true }

    imageCompression(file, options)
        .then(compressedFile => {
            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onerror = e => rej(e)
            reader.onload = () => res(reader.result);
        })
        .catch(e => rej(e))

});


export const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64.replace(/^data:.+;base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) byteNumbers[i] = slice.charCodeAt(i);
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}


export const downloadBlob = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // Aggiungi l'elemento al DOM
    a.click(); // Esegui il click programmato per far partire il download
    document.body.removeChild(a); // Pulisci il DOM rimuovendo l'elemento
    URL.revokeObjectURL(url); // Rilascia l'URL per non gravare su risorse del browser
}


export const getCountryCodeFromCity = () => {
    let country = "US"
    if (Intl) {
        const userTimeZoneArr = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");
        const userCity = userTimeZoneArr[userTimeZoneArr.length - 1];
        country = citywithcountricodes[userCity] || "US"
    }
    return country
}

export const getLemonSqueezyCheckoutUrl = (fbuser, user) => {

    const { displayName } = fbuser || {}; //in case of logout
    const { firstName, lastName, email } = user || {}

    const domain = `https://cvpop.lemonsqueezy.com/checkout/buy`
    // const variantId = "5c891001-1576-4b58-be4a-25c99df9f0fc" // DEV MODE
    const variantId = "4b290347-9315-4e5c-ae8a-553d9f440a24"
    const country = getCountryCodeFromCity()

    const formData = `checkout[email]=${email || ""}&checkout[name]=${firstName || displayName || ""} ${lastName || ""}&checkout[billing_address][country]=${country}`
    const customData = `checkout[custom][user_id]=${fbuser.uid}`

    return `${domain}/${variantId}?${formData}&${customData}`
}

export const initIubendaCookieBanner = () => {

    let codeScript = document.createElement("script");
    let autoBlockinScript = document.createElement("script");
    let gppScript = document.createElement("script");
    let stableScript = document.createElement("script");

    codeScript.type = "text/javascript"
    codeScript.text = `var _iub = _iub || []; _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"cookiePolicyInOtherWindow":true,"countryDetection":true,"enableFadp":true,"enableLgpd":true,"enableTcf":true,"enableUspr":true,"floatingPreferencesButtonDisplay":"bottom-right","googleAdditionalConsentMode":true,"lang":"en","lgpdAppliesGlobally":false,"perPurposeConsent":true,"preferenceCookie":{"expireAfter":180},"siteId":1925745,"tcfPurposes":{"2":"consent_only","7":"consent_only","8":"consent_only","9":"consent_only","10":"consent_only","11":"consent_only"},"whitelabel":false,"cookiePolicyId":53049117, "banner":{ "acceptButtonColor":"#007BFF","acceptButtonDisplay":true,"backgroundOverlay":true,"closeButtonDisplay":false,"customizeButtonColor":"#8E8E93","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeBody":"12px","listPurposes":true,"logo":null,"ownerName":"CVpop","position":"bottom","rejectButtonColor":"#FF443A","rejectButtonDisplay":true,"showTitle":false,"showTotalNumberOfProviders":true }};`;

    autoBlockinScript.type = "text/javascript"
    autoBlockinScript.src = "https://cs.iubenda.com/autoblocking/1925745.js"

    gppScript.type = "text/javascript"
    gppScript.src = "//cdn.iubenda.com/cs/gpp/stable/stub.js"

    stableScript.type = "text/javascript"
    stableScript.src = "//cdn.iubenda.com/cs/stable/iubenda_cs.js"
    stableScript.async = true

    document.body.appendChild(codeScript);
    document.body.appendChild(autoBlockinScript);
    document.body.appendChild(gppScript);
    document.body.appendChild(stableScript);
}

// LOCALIZATION --------------------------------------------
export const setAppLocalization = (c = "en") => {
    moment.locale(c.includes("zh") ? "zh-cn" : c)
    I18n.changeLanguage(c);
}

// ALERTING --------------------------------------------
export const showAlert = (title = "", message = "") => new Promise(res => {
    if (window.electronAPI) return window.electronAPI.showMessageBox({ message: title, detail: message })
    window.alert(`${title}\n\n${message}`)
    res()
})

export const confirmAlert = (message, detail, type = "question", actionText = "OK") => new Promise(res => {

    const result = window.confirm(`${message}\n\n${detail}`)
    if (result) res(result)
})


export const confirmDelete = ({ opt, cb }) => confirmAlert(t('areYouSure'), t('genericDeleteMsg'), "question", t('delete'))
    .then(() => cb())
    .catch(() => { })


// NAVIGATION --------------------------------------------
export const goToPaywall = (offering, platform, n) => {

    if (platform === "macos" || platform === "visionos") return n(`/app/${platform}/paywall`)
    window.LemonSqueezy.Url.Open(offering);

}

export const openUrl = (url, platform) => {

    if (platform === "macos" || platform === "visionos") return window.webkit.messageHandlers["openUrl"].postMessage(url);
    window.open(url, "_blank");
}


// ERROR --------------------------------------------
export const errorHandler = (event = "err_generic", error = {}, avoidFeedbackToUser = false) => {

    // if (error instanceof Error) crashlytics().recordError(error, error.name || event);
    // crashlytics().log(`[err/${event}] ${error.code || "no-code"} - ${error.message || "no-message"}`);
    // analytics().logEvent(event, { error_code: error.code || "no-code", error_message: error.message || "no-message" });

    console.log("===============================================================================");
    console.log(`EVENT   :: ${event}`);
    console.log(`CODE    :: ${error.code || "no-code"}`);
    console.log(`MESSAGE :: ${error.message || "no-message"}`);
    console.log(error);
    console.log("===============================================================================");

    if (!avoidFeedbackToUser) {

        // convert string-string to stringString
        const errCodeKey = (error.code || "").split("/").map((e, i) => `${i === 0 ? e.charAt(0) : e.charAt(0).toUpperCase()}${e.slice(1)}`.replace(/-([a-z])/g, g => g[1].toUpperCase())).join("")

        const title = t(`${errCodeKey}Title`, { defaultValue: t("errTitle") })
        const message = t(`${errCodeKey}Msg`, { defaultValue: t("genericError") })

        if (window.electronAPI) return window.electronAPI.showErrorBox({ title, message })
        window.alert(`${title}\n\n${message}`)

    }

}
