import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        userLogged: false,
        adUserChoices: false
    },
    reducers: {
        setUser(state, action) {
            state.user = action.payload.user
        },
        setUserLogged(state, action) {
            state.userLogged = action.payload
        },
        setAdUserChoices(state, action) {
            state.adUserChoices = action.payload
        },
    }
})

export default userSlice