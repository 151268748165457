module.exports = {

    icons: {
        signapple: {
            light: require('../files/icons/signapple.png'),
            dark: require('../files/icons/signappledark.png'),
        },
        signgoogle: {
            light: require('../files/icons/signgoogle.png'),
            dark: require('../files/icons/signgoogledark.png'),
        },
        avatar: require('../files/icons/avatar.png'),
        avatarcv: require('../files/icons/avatarcv.png'),
        phcompanylogo: require('../files/icons/phcompanylogo.png'),

        featuremultiplecv: require("../files/icons/featuremultiplecv.png"),
        featureaieditor: require("../files/icons/featureaieditor.png"),
        featuretranslate: require("../files/icons/featuretranslate.png"),
        featurenoads: require("../files/icons/featurenoads.png"),
        featuretemplates: require("../files/icons/featuretemplates.png"),
        featureunlockall: require("../files/icons/featureunlockall.png"),
        featuresalary: require("../files/icons/featuresalary.png"),
        featureimport: require("../files/icons/featureimport.png"),
        featureinterview: require("../files/icons/featureinterview.png"),

        filetypecsv: require("../files/icons/csv.png"),
        filetypedoc: require("../files/icons/doc.png"),
        filetypedocx: require("../files/icons/docx.png"),
        filetypepdf: require("../files/icons/pdf.png"),
        filetypeppt: require("../files/icons/ppt.png"),
        filetypepptx: require("../files/icons/pptx.png"),
        filetypexls: require("../files/icons/xls.png"),
        filetypexlsx: require("../files/icons/xlsx.png"),
        filetypejpeg: require("../files/icons/jpeg.png"),
    },

    pictures: {
        genericPlaceholder: require('../files/pictures/generic-placeholder.png'),
        newsWithoutPreview: require('../files/pictures/news-without-preview.png'),
        cvlanding: require('../files/pictures/cv-landing.jpg'),
        previewappappstore: require('../files/pictures/previewappappstore.png'),
        previewappmacappstore: require('../files/pictures/previewappmacappstore.png'),
        previewappplaystore: require('../files/pictures/previewappplaystore.png'),
        previewappwebapp: require('../files/pictures/previewappwebapp.png'),
    },

    models: {
        moscow: require('../files/models/moscow.jpg'),
        milan: require('../files/models/milan.jpg'),
        paris: require('../files/models/paris.jpg'),
        london: require('../files/models/london.jpg'),
        helsinki: require('../files/models/helsinki.jpg'),
        tokyo: require('../files/models/tokyo.jpg'),
        newyork: require('../files/models/newyork.jpg'),
        shanghai: require('../files/models/shanghai.jpg'),
        sydney: require('../files/models/sydney.jpg'),
        atene: require('../files/models/atene.jpg'),
        madrid: require('../files/models/madrid.jpg'),
        amsterdam: require('../files/models/amsterdam.jpg'),
        rio: require('../files/models/rio.jpg'),
        istanbul: require('../files/models/istanbul.jpg'),
        stockholm: require('../files/models/stockholm.jpg'),
        pechino: require('../files/models/pechino.jpg'),
        bruxelles: require('../files/models/bruxelles.jpg'),
        lisbon: require('../files/models/lisbon.jpg'),
        dublin: require('../files/models/dublin.jpg'),
    },

    flags: {
        ar: require('../files/flags/ar.png'),
        bg: require('../files/flags/bg.png'),
        es: require('../files/flags/es.png'),
        cs: require('../files/flags/cs.png'),
        da: require('../files/flags/da.png'),
        de: require('../files/flags/de.png'),
        et: require('../files/flags/et.png'),
        el: require('../files/flags/el.png'),
        en: require('../files/flags/en.png'),
        fr: require('../files/flags/fr.png'),
        hr: require('../files/flags/hr.png'),
        is: require('../files/flags/is.png'),
        it: require('../files/flags/it.png'),
        lv: require('../files/flags/lv.png'),
        lt: require('../files/flags/lt.png'),
        hu: require('../files/flags/hu.png'),
        mk: require('../files/flags/mk.png'),
        mt: require('../files/flags/mt.png'),
        nl: require('../files/flags/nl.png'),
        nb: require('../files/flags/nb.png'),
        no: require('../files/flags/nb.png'),
        pl: require('../files/flags/pl.png'),
        pt: require('../files/flags/pt.png'),
        ro: require('../files/flags/ro.png'),
        sk: require('../files/flags/sk.png'),
        sl: require('../files/flags/sl.png'),
        fi: require('../files/flags/fi.png'),
        sv: require('../files/flags/sv.png'),
        tr: require('../files/flags/tr.png'),
        zh: require('../files/flags/zh.png'),
        ja: require('../files/flags/ja.png'),
    },

    patterns: {
        pattern1: require('../files/patterns/pattern-1.png'),
        pattern2: require('../files/patterns/pattern-2.png'),
        pattern3: require('../files/patterns/pattern-3.png'),
        pattern4: require('../files/patterns/pattern-4.png'),
        pattern5: require('../files/patterns/pattern-5.png'),
        pattern6: require('../files/patterns/pattern-6.png'),
        pattern7: require('../files/patterns/pattern-7.png'),
        pattern8: require('../files/patterns/pattern-8.png'),
        pattern9: require('../files/patterns/pattern-9.png'),
        pattern10: require('../files/patterns/pattern-10.png'),
        pattern11: require('../files/patterns/pattern-11.png'),
        pattern12: require('../files/patterns/pattern-12.png'),
    },

    bgImages: {
        bgImage1: require('../files/bg-images/bg-image-1.jpg'),
        bgImage2: require('../files/bg-images/bg-image-2.jpg'),
        bgImage3: require('../files/bg-images/bg-image-3.jpg'),
        bgImage4: require('../files/bg-images/bg-image-4.jpg'),
        bgImage5: require('../files/bg-images/bg-image-5.jpg'),
        bgImage6: require('../files/bg-images/bg-image-6.jpg'),
        bgImage7: require('../files/bg-images/bg-image-7.jpg'),
        bgImage8: require('../files/bg-images/bg-image-8.jpg'),
        bgImage9: require('../files/bg-images/bg-image-9.jpg'),
        bgImage10: require('../files/bg-images/bg-image-10.jpg'),
        bgImage11: require('../files/bg-images/bg-image-11.jpg'),
        bgImage12: require('../files/bg-images/bg-image-12.jpg'),
        bgImage13: require('../files/bg-images/bg-image-13.jpg'),
        bgImage14: require('../files/bg-images/bg-image-14.jpg'),
        bgImage15: require('../files/bg-images/bg-image-15.jpg'),
    },

    logos: {
        app: require('../files/bootsplash/bootsplash_logo_original.png'),
        apptext: {
            light: require('../files/logos/apptext.png'),
            dark: require('../files/logos/apptextdark.png')
        },
        google: {
            light: require('../files/logos/google.png'),
            dark: require('../files/logos/googledark.png')
        },
        freepik: require('../files/logos/freepik.png'),
        storyset: {
            light: require('../files/logos/storyset.png'),
            dark: require('../files/logos/storysetdark.png'),
        },
        udemy: require('../files/logos/udemy.png'),
        store: {
            appstore: require('../files/logos/store/appstore.png'),
            macappstore: require('../files/logos/store/macappstore.png'),
            playstore: require('../files/logos/store/playstore.png'),
            webapp: require('../files/logos/store/webapp.png'),
        },
    },

    illustrations: {
        cv: require('../files/illustrations/cv.png'),

        reauth: require('../files/illustrations/login.png'),
        login: require('../files/illustrations/login.png'),
        convert: require('../files/illustrations/convert.png'),

        video: require('../files/illustrations/video.png'),
        signature: require('../files/illustrations/signature.png'),
        archive: require('../files/illustrations/archive.png'),
        rating: require('../files/illustrations/rating.png'),
        terms: require('../files/illustrations/terms.png'),
        error: require('../files/illustrations/error.png'),
        search: require('../files/illustrations/search.png'),
        interview: require('../files/illustrations/interview.png'),
        maintenance: require('../files/illustrations/maintenance.png'),
        update: require('../files/illustrations/update.png'),
        customization: require('../files/illustrations/customization.png'),
        confidence: require('../files/illustrations/confidence.png'),
        review: require('../files/illustrations/review.png'),
        standout: require('../files/illustrations/standout.png'),
        hired: require('../files/illustrations/hired.png'),
        personaldata: require('../files/illustrations/personaldata.png'),
        notification: require('../files/illustrations/notification.png'),
        avatar: require('../files/illustrations/avatar.png'),
        warning: require('../files/illustrations/warning.png'),
        success: require('../files/illustrations/success.png'),
        expired: require('../files/illustrations/expired.png'),
        notfound: require('../files/illustrations/notfound.png'),
        publiclink: require('../files/illustrations/publiclink.png'),
        voice: require('../files/illustrations/voice.png'),
        aiassistant: require('../files/illustrations/aiassistant.png'),

        gift: require('../files/illustrations/gift.png'),

        work: require('../files/illustrations/work.png'),
        education: require('../files/illustrations/education.png'),
        location: require('../files/illustrations/location.png'),
        quiz: require('../files/illustrations/quiz.png'),
        salary: require('../files/illustrations/salary.png'),
        email: require('../files/illustrations/email.png'),
        ability: require('../files/illustrations/ability.png'),
        foreignlanguage: require('../files/illustrations/foreignlanguage.png'),
        instantmessaging: require('../files/illustrations/instantmessaging.png'),
        socialaccount: require('../files/illustrations/socialaccount.png'),
        foreignlanguagecert: require('../files/illustrations/foreignlanguagecert.png'),
        website: require('../files/illustrations/website.png'),
        expertisearea: require('../files/illustrations/expertisearea.png'),
        telephone: require('../files/illustrations/telephone.png'),
        additionalinfo: require('../files/illustrations/additionalinfo.png'),
        driverlicencespecial: require('../files/illustrations/driverlicencespecial.png'),
        portfolio: require('../files/illustrations/portfolio.png'),
        attachment: require('../files/illustrations/attachment.png'),

        premium: require('../files/illustrations/premium.png'),
        offeronboarding: require('../files/illustrations/offeronboarding.png'),
        offerperiodic: require('../files/illustrations/offerperiodic.png'),
        offerads: require('../files/illustrations/offerads.png'),
        offerqa: require('../files/illustrations/offerdiscount.png'),
        offerunsubscribe: require('../files/illustrations/offerdiscount.png'),

        cvservice: require('../files/illustrations/cvservice.png'),
        letterservice: require('../files/illustrations/letterservice.png'),
        linkedinservice: require('../files/illustrations/linkedinservice.png'),
        coachservice: require('../files/illustrations/coachservice.png'),

    },

}