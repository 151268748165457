module.exports = {
    regex: {
        mail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },

    supportedWebPlatforms: ["web", "macos", "visionos"],

    offeringIdList: {
        ios: [
            // "cvpop_699_1w_0",
            // "cvpop_799_1w_0",
            // "cvpop_899_1w_0",
            // "cvpop_999_1w_0",
            "cvpop_1299_1w_0",
            "cvpop_1299_1w_3d0",
            // "cvpop_1299_1m_0",
            // "cvpop_1499_1m_0",
            // "cvpop_1799_1m_0",
            // "cvpop_1999_1m_0",
            // "cvpop_1999_1m_3d0",
            // "cvpop_2499_1m_0",
            // "cvpop_2499_1m_3d0",
            // "cvpop_2499_3m_0",
            // "cvpop_2999_3m_0",
            // "cvpop_3499_3m_0",
            // "cvpop_3999_3m_0",
            // "cvpop_4499_3m_0",
            // "cvpop_3999_3m_3d0",
            // "cvpop_4499_3m_3d0",
        ],
        android: [
            "cvpop_premium_v1"
        ]
    },

    urls: {
        apps: {
            ios: {
                mail: "https://apps.apple.com/it/app/mail/id1108187098"
            }
        },
        legal: {
            terms: "https://www.iubenda.com/terms-and-conditions/53049117",
            privacy: "https://www.iubenda.com/privacy-policy/53049117",
            cookie: "https://www.iubenda.com/privacy-policy/53049117/cookie-policy"
        },
        store: {
            appstore: "https://apps.apple.com/us/app/intelligent-cv-resume-builder/id1473493711",
            googleplay: "https://play.google.com/store/apps/details?id=com.curriculify.app",
        },
        subscription: {
            ios: "https://apps.apple.com/account/subscriptions",
            android: "https://play.google.com/store/account/subscriptions?package=com.curriculify.app",
            web: "https://cvpop.lemonsqueezy.com/billing"
        },
        social: {
            facebook: "https://www.facebook.com/app.cvpop",
            instagram: "https://www.instagram.com/app.cvpop",
            linkedin: "https://www.linkedin.com/company/cvpop",
            x: "https://x.com/app_cvpop",
            youtube: "https://www.youtube.com/channel/UCE1o64SOoxJoIMhXY1zDUkw",
        }
    },

    fbConfigDef: {
        removeBg: true,
        docxExport: true,
        compressCv: true,
        linkedinImport: true,

        jobSearch: true,
        courseSearch: true,
        bookSearch: true,
        salarySearch: true,

        createInterview: true,
        createQuiz: true,
        createText: true,

        openAIOn: true,

        trialPlanOn: false,
    }
}