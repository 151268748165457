const { countries, languages } = require("cvpop-collections-sdk");

const _ = require("lodash")
const moment = require("moment/min/moment-with-locales.min.js")
const semver = require("semver")
const EventEmitter = require('eventemitter3')

module.exports = {
    ee: new EventEmitter(),

    compareVersions: (v1, v2) => {
        const v1parts = v1.split('.');
        const v2parts = v2.split('.');

        if (v1parts.length < 3) v1 = v1 + '.0';
        if (v2parts.length < 3) v2 = v2 + '.0';

        return semver.compare(v1, v2)
    },

    removeKeysFromObj: (obj, keysToOmit) => {

        const omitFromObject = obj => _.transform(obj, (result, value, key) => {
            if (key in _.keyBy(keysToOmit)) return;
            result[key] = _.isObject(value) ? omitFromObject(value) : value;
        })

        return omitFromObject(obj);
    },

    getTextFromHtml: html => html.replace(/\n/ig, '')
        .replace(/<\/\s*(?:p|div)>/ig, '\n')
        .replace(/<br[^>]*\/?>/ig, '\n')
        .replace(/<[^>]*>/ig, '')
        .replace('&nbsp;', ' ')
        .replace(/[^\S\r\n][^\S\r\n]+/ig, ' '),

    formatBytes: (a, b = 2) => {
        if (0 === a) return "0 Bytes";
        const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
        return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    },

    sleep: async (ms = 1000) => await new Promise(resolve => setTimeout(resolve, ms)),

    createRandomString: (length = 6) => {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) result += chars.charAt(Math.floor(Math.random() * chars.length));
        return result;
    },

    getEnglishLabelFromLanguageCode: c => (((_.find(languages.en, o => o.code === (c === "no" ? "nb" : c))) || { label: "english" }).label || "english"),

    dateToString: (cv, d, type = "", targetLng = undefined) => {

        let date = _.clone(d)

        if (!date.Year) return ('')
        if (parseInt(date.Day) < 10) date.Day = '0' + date.Day;
        if (parseInt(date.Month) < 10) date.Month = '0' + date.Month;

        let vType = cv.Metadata.viewBirthDate;
        let fType = cv.Metadata.formatBirthDate || null;

        if (type.toUpperCase() === "EDUCATION") {
            vType = cv.Metadata.viewEducationDate;
            fType = cv.Metadata.formatEducationDate || null;
        }
        if (type.toUpperCase() === "WORK") {
            vType = cv.Metadata.viewWorkDate;
            fType = cv.Metadata.formatWorkDate || null;
        }
        if (type.toUpperCase() === "CV_DATE") {
            vType = cv.Metadata.viewCvDate;
            fType = cv.Metadata.formatCvDate || null;
        }

        let mFormat = fType === 'YEAR' ? "YY" : fType === 'MONTH_YEAR' ? "MMM YY" : "D MMM YY";
        if (vType === "text/short") mFormat = fType === 'YEAR' ? "YYYY" : fType === 'MONTH_YEAR' ? "MMM YYYY" : "D MMM YYYY";
        if (vType === "text/long") mFormat = fType === 'YEAR' ? "YYYY" : fType === 'MONTH_YEAR' ? "MMMM YYYY" : "DD MMMM YYYY";
        if (vType === "text/long/suffix") mFormat = fType === 'YEAR' ? "YYYY" : fType === 'MONTH_YEAR' ? "MMMM YYYY" : "Do MMMM YYYY";
        if (vType === "numeric/long") mFormat = fType === 'YEAR' ? "YYYY" : fType === 'MONTH_YEAR' ? "MM/YYYY" : "DD/MM/YYYY";
        if (vType === "numeric/medium") mFormat = fType === 'YEAR' ? "YYYY" : fType === 'MONTH_YEAR' ? "M/YYYY" : "D/M/YYYY";
        if (vType === "numeric/short") mFormat = fType === 'YEAR' ? "YY" : fType === 'MONTH_YEAR' ? "M/YY" : "D/M/YY";
        if (vType === "numeric/long/us") mFormat = fType === 'YEAR' ? "YYYY" : fType === 'MONTH_YEAR' ? "MM/YYY" : "MM/DD/YYYY";

        if (targetLng === "zh") targetLng = "zh-cn"
        return moment(`${date.Month}-${date.Day}-${date.Year}`, "MM-DD-YYYY", targetLng, true).format(mFormat)

    },

    formatAddress: (addressObj = {}, targetLng) => {
        const lng = targetLng === 'nb' ? 'no' : targetLng
        let addressTxt = [addressObj.AddressLine, addressObj.PostalCode, addressObj.Municipality].filter(Boolean).join(", ");
        if (addressObj.Country && addressObj.Country.Code) // nei file di assetns nb non esiste ma c'è solo no
            addressTxt += ` (${(countries[lng].find(v => v.code === addressObj.Country.Code) || { label: "" }).label})`
        return (addressTxt) ? addressTxt : null;
    },
}
