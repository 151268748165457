const common = require("./src/common")
const cv = require("./src/cv")
const style = require("./src/style")


module.exports = {
    regex: common.regex,
    urls: common.urls,
    supportedWebPlatforms: common.supportedWebPlatforms,
    offeringIdList: common.offeringIdList,

    fbConfigDef: common.fbConfigDef,
    defaultCvObject: cv.defaultCvObject,
    defaultModelLayout: cv.defaultModelLayout,
    modelCatalogue: cv.modelCatalogue,
    fontCatalogue: cv.fontCatalogue,
    colorCatalogue: cv.colorCatalogue,
    basePatternCatalogue: cv.basePatternCatalogue,
    baseImageCatalogue: cv.baseImageCatalogue,
    cvPickerItems: cv.cvPickerItems,
    prewrittenPhraseCategoryCatalogue: cv.prewrittenPhraseCategoryCatalogue,
    sectionGroupedCatalogue: cv.sectionGroupedCatalogue,

    colors: style.colors,
    margin: style.margin,
    padding: style.padding,
    typography: style.typography,
    layout: style.layout
}