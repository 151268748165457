const { urls } = require("cvpop-constants-sdk");

const _ = require("lodash")

// Shared functions
const isProUser = (user = {}) => (user || {}).plan === "PRO" || (user || {}).plan === "SUBSCRIBED";

module.exports = {
    // ------------------------------------------------------------------------------------------------------
    isProUser: isProUser,

    isAmsUser: (user = {}) => ((user || {}).isAms || __DEV__) && !(user || {}).isMarketing,

    isAdReadyUser: (user = {}) => {
        if (isProUser(user) || user.isTA) return false // never ready in this case
        const adTime = parseInt(user.lastInterstitialTime || 0);
        return (((new Date().getTime() - adTime) / 1000) > 240) // each 4 min

    },

    isPaywallReadyUser: (user = {}) => {
        if (isProUser(user) || user.isTA) return false // never ready in this case
        const adTime = parseInt(user.lastPaywallTime || 0);
        return (((new Date().getTime() - adTime) / 1000) > 3600) // each 1h
    },

    isEligibleForGiftUser: (user = {}) => (user && !user.hasGetGift && ["it"].includes(user.language)),

    isEligibleForFirstCvUser: (user = {}) => (user && !user.hasAddFirstCv),

    isEligibleForAdsUser: (user = {}, adType = "INT") => {
        const adTime = parseInt(adType === "INT" ? user.lastInterstitialTime || 0 : user.lastRewardedTime || 0);
        return (((new Date().getTime() - adTime) / 1000) > (adType === "INT" ? 15 * 5 : 15 * 60)) // each 5 min for INT, 15 for video
    },


    // ------------------------------------------------------------------------------------------------------
    userHasCap: (user = {}, idCap) => {
        if (isProUser(user || {}) || idCap === "ALWAYS_YES") return true;
        return _.findIndex((user || {}).capability, o => o.id === idCap) >= 0;
    },

    // ------------------------------------------------------------------------------------------------------
    getNewUserData: (fbuser = {}, language = "", platform = "", osVersion = "") => {

        const { isAnonymous = false, email = "" } = fbuser
        const now = new Date().getTime();

        return {
            language,
            isAnonymous,
            email,
            plan: 'FREE',
            referalCodes: [],
            lastAskRateTime: null,
            updatePlanDate: null,
            lastInterstitialTime: now - (6 * 60 * 1000), // 6 minute without ads
            lastRewardedTime: null,
            platform,
            osVersion,
            lastPaywallTime: now - (55 * 60 * 1000), // 5minutes to show periodic paywall
            introChoices: { goal: "", seniority: "", timing: "", personality: "" },
            consents: {
                adsConsent: false, adsConsentTime: now,
                marketingConsent: false, marketingConsentTime: now,
                termsAcceptance: true, termsAcceptanceTime: now,
            }
        }

    },

    getAvatarUser: (user = {}, platform = "") => {
        const { avatar } = user || {}
        if (!avatar) return null
        const uri = `data:image/png;base64,${avatar}`
        return ["ios", "android"].includes(platform) ? { uri } : uri
    },


    getUnsubcribeUserUrl: ({ platform, planSubscriptionProvider, planSubscriptionManageUrl, planSubscriptionStore }) => {

        if (planSubscriptionProvider === "LEMON_SQUEEZY") return (planSubscriptionManageUrl || urls.subscription.web)
        if (planSubscriptionStore === "APP_STORE") return urls.subscription.ios
        if (planSubscriptionStore === "PLAY_STORE") return urls.subscription.android
        return urls.subscription[platform] || urls.subscription.web

    },
}
